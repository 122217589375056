import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import get from "lodash/fp/get";

import { useDispatchWithLocale, useTranslation, useWebChat } from "hooks";
import { selectIsUserFullySignedIn, selectProfile } from "store/profile";
import { BookingFlowLoadingIndicator } from "BookingFlow/components";
import { selectUpcomingTripsOnly } from "store/bookingHistory/bookingHistory.selectors";
import { setShouldOpen as toastShouldOpen } from "store/toastNotification";
import { selectGlobalSettings } from "store/globalSettings";
import { getStaticSiteProfileMyStaysLink } from "utils/externalLinks";
import UpcomingTripView from "./UpcomingTripView";
import { ItineraryView } from "../ItineraryView";
import * as profileRoutes from "../profileRoutes";
import ArrivalTransportation from "../components/TransportationAmenities/ArrivalTransportation";
import ArrivalAmenities from "../components/TransportationAmenities/ArrivalAmenities";
import useUpcomingTrip from "./useUpcomingTrip";

const LOCAL_VIEWS = {
  MAIN: "main",
  ARRIVAL_TRANSPORTATION: "arrival-transportation",
  AMENITY_REQUESTS: "amenity-requests",
};

function Routes({
  localView,
  closeView,
  upcomingTrip,
  itineraryPath,
  availableArrivalTransportations,
  isOnItineraryPage,
  availableWelcomeAmenities,
  hotelCode,
  reservationId,
  highlightsMap,
  bookingMessages,
  taxes,
  webChatEnabled,
  searchParams,
  canRequestTransportation,
  hasTransportation,
  showArrivalTransportation,
  canRequestWelcomeAmenities,
  showAmenityRequests,
  phoneNumberPrimary,
  bookingConfig,
  allowCancellation,
  bookingId,
  cancelByDate,
  canChangeBooking,
  changeArrivalTime,
  comment,
  employeeRate,
  hasErrors,
  phoneNumber,
  priceViewable,
  propertyContent,
  showLoadingSpinner,
  showSavingSpinner,
  updateAdditionalRequests,
  updateMostImportantThing,
  updateRoomGuests,
  updateTravelDates,
  updateTripPurpose,
  youCanBookMessage,
  arrivalTransportation,
  isFullySignedIn,
}) {
  const { t } = useTranslation();

  const { isInitialised, openWebChat } = useWebChat({
    hotelCode,
    booking: upcomingTrip,
    searchParams,
  });

  const isWebChatEnabled = isInitialised && webChatEnabled;

  return (
    <>
      {isFullySignedIn && localView === LOCAL_VIEWS.ARRIVAL_TRANSPORTATION && (
        <ArrivalTransportation
          goBack={closeView}
          booking={upcomingTrip}
          itineraryPath={itineraryPath}
          availableArrivalTransportations={availableArrivalTransportations}
          isOnItineraryPage={isOnItineraryPage}
        />
      )}

      {isFullySignedIn && localView === LOCAL_VIEWS.AMENITY_REQUESTS && (
        <ArrivalAmenities
          goBack={closeView}
          booking={upcomingTrip}
          itineraryPath={itineraryPath}
          availableWelcomeAmenities={availableWelcomeAmenities}
          goBackButtonLabel={
            isOnItineraryPage ? t("Back to itinerary") : t("Back to trip")
          }
          isOnItineraryPage={isOnItineraryPage}
        />
      )}

      <Switch>
        <Route
          path={profileRoutes.profileBookingItineraryPath.path}
          render={() => (
            <ItineraryView
              hotelCode={hotelCode}
              reservationId={reservationId}
              highlightsMap={highlightsMap}
              bookingMessages={bookingMessages}
              taxes={taxes}
              goToArrivalTransportationView={
                canRequestTransportation &&
                !hasTransportation &&
                showArrivalTransportation
              }
              goToArrivalAmenitiesView={
                canRequestWelcomeAmenities && showAmenityRequests
              }
              //
              // WebChat
              isWebChatEnabled={isWebChatEnabled}
              openWebChat={openWebChat}
            />
          )}
        />

        <Route
          render={() => (
            <UpcomingTripView
              phoneNumberPrimary={phoneNumberPrimary}
              bookingConfig={bookingConfig}
              hotelCode={hotelCode}
              reservationId={reservationId}
              allowCancellation={allowCancellation}
              bookingId={bookingId}
              bookingMessages={bookingMessages}
              cancelByDate={cancelByDate}
              canChangeBooking={canChangeBooking}
              changeArrivalTime={changeArrivalTime}
              comment={comment}
              employeeRate={employeeRate}
              hasErrors={hasErrors}
              highlightsMap={highlightsMap}
              phoneNumber={phoneNumber}
              priceViewable={priceViewable}
              propertyContent={propertyContent}
              showLoadingSpinner={showLoadingSpinner}
              showSavingSpinner={showSavingSpinner}
              taxes={taxes}
              upcomingTrip={upcomingTrip}
              updateAdditionalRequests={updateAdditionalRequests}
              updateMostImportantThing={updateMostImportantThing}
              updateRoomGuests={updateRoomGuests}
              updateTravelDates={updateTravelDates}
              updateTripPurpose={updateTripPurpose}
              youCanBookMessage={youCanBookMessage}
              //
              // WebChat
              isWebChatEnabled={isWebChatEnabled}
              openWebChat={openWebChat}
              //
              // Welcome Amentities
              canRequestWelcomeAmenities={canRequestWelcomeAmenities}
              goToAmenityRequests={showAmenityRequests}
              //
              // Arrival Transportation
              canRequestTransportation={canRequestTransportation}
              goToArrivalTransportationView={showArrivalTransportation}
              arrivalTransportation={arrivalTransportation}
              hasTransportation={hasTransportation}
            />
          )}
        />
      </Switch>
    </>
  );
}

export default function UpcomingTripViews({ hotelCode, reservationId }) {
  const { locale } = useTranslation();

  const profile = useSelector(selectProfile);
  const isFullySignedIn = useSelector(selectIsUserFullySignedIn);
  const upcomingTrips = useSelector(selectUpcomingTripsOnly);
  const { enableProfileOnWww } = useSelector(selectGlobalSettings);

  const hasMatchingTrip = upcomingTrips
    .flatMap(({ hotelProducts }) =>
      hotelProducts.map((hotelProduct) => hotelProduct.reservationId)
    )
    .includes(reservationId);

  const {
    phoneNumberPrimary,
    bookingConfig,
    allowCancellation,
    bookingId,
    bookingMessages,
    cancelByDate,
    canChangeBooking,
    changeArrivalTime,
    comment,
    contactEmail,
    employeeRate,
    hasErrors,
    highlightsMap,
    phoneNumber,
    priceViewable,
    propertyContent,
    showLoadingSpinner,
    showSavingSpinner,
    taxes,
    upcomingTrip,
    updateAdditionalRequests,
    updateMostImportantThing,
    updateRoomGuests,
    updateTravelDates,
    updateTripPurpose,
    youCanBookMessage,

    // WebChat
    webChatEnabled,
    searchParams,

    // Welcome Amentities
    canRequestWelcomeAmenities,
    availableWelcomeAmenities,

    // Arrival Transportation
    hasTransportation,
    availableArrivalTransportations,
    arrivalTransportation,
    canRequestTransportation,
  } = useUpcomingTrip({
    reservationId,
    hotelCode,
    surname: get(["name", "surname"])(profile),
  });

  const [localView, setLocalView] = useState(LOCAL_VIEWS.MAIN);

  const showArrivalTransportation = () => {
    console.log("setting local view to transport");
    setLocalView(LOCAL_VIEWS.ARRIVAL_TRANSPORTATION);
  };

  const showAmenityRequests = () => {
    console.log("setting local view to amenities");
    setLocalView(LOCAL_VIEWS.AMENITY_REQUESTS);
  };

  const closeView = () => setLocalView(LOCAL_VIEWS.MAIN);

  useEffect(() => {
    if (
      !isFullySignedIn &&
      (localView === LOCAL_VIEWS.ARRIVAL_TRANSPORTATION ||
        localView === LOCAL_VIEWS.AMENITY_REQUESTS)
    ) {
      console.log("setting local view to main");
      setLocalView(LOCAL_VIEWS.MAIN);
    }
  }, [isFullySignedIn]);

  const dispatchWithLocale = useDispatchWithLocale();

  if (!hasMatchingTrip) {
    if (enableProfileOnWww) {
      window.location.href = getStaticSiteProfileMyStaysLink(locale);
      return null;
    }

    dispatchWithLocale(
      toastShouldOpen({
        shouldOpen: "onNextView",
        notificationText:
          "Sorry, we cannot find the reservation on this profile.",
      })
    );
    return (
      <Route>
        <Redirect
          to={`${profileRoutes.profilePath.to({ locale })}${
            upcomingTrips.length > 0 ? "#upcoming-trips" : ""
          }`}
        />
      </Route>
    );
  }

  if (showLoadingSpinner) {
    return <BookingFlowLoadingIndicator opaque />;
  }

  const itineraryPath = profileRoutes.profileBookingItineraryPath.to({
    locale,
    hotelCode,
    reservationId,
  });

  const isOnItineraryPage = window.location.pathname.includes("/itinerary");

  return (
    <Routes
      localView={localView}
      closeView={closeView}
      upcomingTrip={upcomingTrip}
      itineraryPath={itineraryPath}
      availableArrivalTransportations={availableArrivalTransportations}
      isOnItineraryPage={isOnItineraryPage}
      availableWelcomeAmenities={availableWelcomeAmenities}
      hotelCode={hotelCode}
      reservationId={reservationId}
      highlightsMap={highlightsMap}
      bookingMessages={bookingMessages}
      taxes={taxes}
      webChatEnabled={webChatEnabled}
      searchParams={searchParams}
      canRequestTransportation={canRequestTransportation}
      hasTransportation={hasTransportation}
      showArrivalTransportation={showArrivalTransportation}
      canRequestWelcomeAmenities={canRequestWelcomeAmenities}
      showAmenityRequests={showAmenityRequests}
      phoneNumberPrimary={phoneNumberPrimary}
      bookingConfig={bookingConfig}
      allowCancellation={allowCancellation}
      bookingId={bookingId}
      cancelByDate={cancelByDate}
      canChangeBooking={canChangeBooking}
      changeArrivalTime={changeArrivalTime}
      comment={comment}
      contactEmail={contactEmail}
      employeeRate={employeeRate}
      hasErrors={hasErrors}
      phoneNumber={phoneNumber}
      priceViewable={priceViewable}
      propertyContent={propertyContent}
      showLoadingSpinner={showLoadingSpinner}
      showSavingSpinner={showSavingSpinner}
      updateAdditionalRequests={updateAdditionalRequests}
      updateMostImportantThing={updateMostImportantThing}
      updateRoomGuests={updateRoomGuests}
      updateTravelDates={updateTravelDates}
      updateTripPurpose={updateTripPurpose}
      youCanBookMessage={youCanBookMessage}
      arrivalTransportation={arrivalTransportation}
      isFullySignedIn={isFullySignedIn}
    />
  );
}
