import env from "config/env";

const { STATIC_SITE_URL, PREFERRED_PARTNER_DOMAIN } = env;

export const getBestRateGuaranteeLink = (locale) =>
  `${STATIC_SITE_URL}/${locale}/landing_pages/corporate/best_rate_guarantee/`;

export const getPressLink = () => "http://press.fourseasons.com";

export const getMagazinesLink = () => "http://magazine.fourseasons.com";

export const getAboutLink = (locale) =>
  `${STATIC_SITE_URL}/${locale}/about_four_seasons/`;

export const getCareersLink = (locale) => {
  switch (locale) {
    case "fr":
      return "http://carrieres.fourseasons.com/";

    case "zh":
      return "http://jobs.fourseasons.cn/";

    default:
      return "http://jobs.fourseasons.com";
  }
};

export const getLegalLink = (locale) => `${STATIC_SITE_URL}/${locale}/legal/`;

export const getPrivacyLink = (locale) =>
  `${STATIC_SITE_URL}/${locale}/privacy/`;

export const getAccessibilityLink = (locale = "") => {
  switch (locale) {
    case "zh":
      return `${STATIC_SITE_URL}/${locale}/legal/accessibility_policy/`;

    default:
      return `${STATIC_SITE_URL}/legal/accessibility_policy/`;
  }
};

export const getCookiePolicyLink = (locale) =>
  `${STATIC_SITE_URL}/${locale}/cookiepolicy/`;

export const getTollFreeNumbersLink = (locale = "") => {
  switch (locale) {
    case "es":
    case "fr":
    case "zh":
      return `${STATIC_SITE_URL}/${locale}/global_toll_free_telephone_numbers/`;

    default:
      return `${STATIC_SITE_URL}/global_toll_free_telephone_numbers/`;
  }
};

export const getFindAHotelLink = (locale) =>
  `${STATIC_SITE_URL}/${locale}/find_a_hotel_or_resort/`;

export const getPropertyContactUsLink = ({ locale = "en", propertyUrlName }) =>
  `${STATIC_SITE_URL}/content/fourseasons/${locale}/properties/${propertyUrlName}/contact_us.html`;

export const getPPReservationServicesFormLink = () =>
  `${PREFERRED_PARTNER_DOMAIN}/resources/reservation_services/`;

export const getStaticSiteProfileLink = (locale) =>
  `${STATIC_SITE_URL}${locale === "en" ? "" : `/${locale}`}/profile`;

export const getStaticSiteProfileMyStaysLink = (locale) =>
  `${STATIC_SITE_URL}${locale === "en" ? "" : `/${locale}`}/profile/my-stays`;

export const getStaticSiteProfileSignInLink = (locale, redirectAfterSignIn) =>
  `${STATIC_SITE_URL}${locale === "en" ? "" : `/${locale}`}/sign-in${
    !!redirectAfterSignIn && `?afterSignInRedirectTo=${redirectAfterSignIn}`
  }`;
