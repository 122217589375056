import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTranslation } from "hooks";
import { selectHasOwnedProperties } from "store/profile";
import { selectIsEmployeeProfile } from "store/employeeProfile";
import * as profileRoutes from "Profile/profileRoutes";
import { getCardDisplayStatus } from "Profile/utils/membershipCardStatus";
import { selectUpcomingTripsOnly } from "store/bookingHistory/bookingHistory.selectors";
import {
  selectMemberDetails,
  selectHasMemberDetails,
} from "store/memberDetails";
import ProfileNavigationItem from "./ProfileNavigationItem";
import useNavigation from "./EditProfileView/hooks/useNavigation";
import { selectGlobalSettings } from "store/globalSettings";
import { getStaticSiteProfileLink, getStaticSiteProfileMyStaysLink } from "utils/externalLinks";

function ProfileNavigation() {
  const { t, locale } = useTranslation();

  const hasOwnedProperties = useSelector(selectHasOwnedProperties);
  const upcomingTrips = useSelector(selectUpcomingTripsOnly);
  const isEmployee = useSelector(selectIsEmployeeProfile);
  let hasCardsToDisplay = false;
  const hasMemberDetails = useSelector(selectHasMemberDetails);
  const memberDetails = useSelector(selectMemberDetails);
  const { enableProfileOnWww } = useSelector(selectGlobalSettings);

  if (hasMemberDetails) {
    const cardsToDisplay = memberDetails.Memberships.filter((membership) => {
      return getCardDisplayStatus(membership);
    });
    if (cardsToDisplay.length > 0) {
      hasCardsToDisplay = true;
    }
  }

  const navigations = [
    {
      hash: "",
      label: "Profile",
      selectable: false,
      pathname: enableProfileOnWww ? getStaticSiteProfileLink(locale) : undefined
    },
    ...(hasCardsToDisplay
      ? [
          {
            hash: !enableProfileOnWww && "your-memberships",
            label: "Memberships",
            pathname: enableProfileOnWww ? `${getStaticSiteProfileLink(locale)}/memberships` : undefined
          },
        ]
      : []),
    ...(hasOwnedProperties
      ? [
          {
            hash: "your-residence",
            label: "Your Residence",
          },
        ]
      : []),
    ...(upcomingTrips.length > 0
      ? [
          {
            hash: !enableProfileOnWww && "upcoming-trips",
            label: "Upcoming Trips",
            pathname: enableProfileOnWww ? getStaticSiteProfileMyStaysLink(locale) : undefined
          },
        ]
      : []),
    {
      hash: !enableProfileOnWww && "your-preferences",
      label: "Your Preferences",
      pathname: enableProfileOnWww ? `${getStaticSiteProfileLink(locale)}/account/preferences` : undefined
    },
    {
      hash: !enableProfileOnWww && "your-interests",
      label: "Your Interests",
      pathname: enableProfileOnWww ? `${getStaticSiteProfileLink(locale)}/account/interests` : undefined
    },
    {
      hash: !enableProfileOnWww && "request-an-invoice",
      label: "Request an Invoice",
      pathname: enableProfileOnWww ? getStaticSiteProfileMyStaysLink(locale) : undefined
    },
    ...(isEmployee
      ? [
          {
            key: "employee",
            label: "Employee Travel",
            pathname: profileRoutes.employeeProfilePath.to({ locale }),
            altSelectPath: [
              profileRoutes.employeeStayHistoryPath.to({ locale }),
            ],
          },
        ]
      : []),
  ];

  const { scrolledToSection, scrollToSection } = useNavigation({
    navigations: navigations.filter(({ hash }) => !!hash),
    topAdjust: 106,
  });
  const location = useLocation();

  return (
    <div className="Secondary-Navigation-bar">
      <ul className="Secondary-Navigation-links">
        {navigations.map(
          ({
            key,
            hash,
            label,
            selectable,
            pathname = profileRoutes.profilePath.to({ locale }),
            altSelectPath = [],
          }) => (
            <ProfileNavigationItem
              key={key || hash}
              href={enableProfileOnWww ? pathname : undefined}
              to={{
                pathname,
                hash,
              }}
              onClick={
                hash
                  ? (evt) => {
                      evt.preventDefault();
                      scrollToSection(hash);
                    }
                  : undefined
              }
              isSelected={
                selectable !== false &&
                (pathname === location.pathname ||
                  altSelectPath.includes(location.pathname)) &&
                (!hash || hash === scrolledToSection)
              }
            >
              {t(label)}
            </ProfileNavigationItem>
          )
        )}
      </ul>
    </div>
  );
}

export default ProfileNavigation;
