/* eslint-disable camelcase */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { parse, format } from "date-fns";
import get from "lodash/get";

import env from "config/env";
import onlyUnique from "utils/onlyUnique";
import {
  BookingFlowLoadingIndicator,
  WebChatButton,
} from "BookingFlow/components";
import { useDispatchWithLocale, useTranslation } from "hooks";
import { selectUpcomingTripsOnly } from "store/bookingHistory/bookingHistory.selectors";
import { selectAccommodationByRoomTypeId } from "store/accommodationsContent";
import { selectBookingByReservationId } from "store/bookings";
import { selectRoomGuests } from "store/bookingComments";
import {
  selectGuestRequests,
  selectHasGuestRequests,
} from "store/guestRequests";
import {
  selectAllPropertyContent,
  fetchPropertyContent,
  fetchPropertyContentCancel,
} from "store/propertyContent";
import { selectGlobalSettings } from "store/globalSettings";
import * as profileRoutes from "Profile/profileRoutes";
import * as authenticationRoutes from "Authentication/authenticationRoutes";
import { Dropdown } from "Profile/components";
import { getQueryString } from "utils/utils";
import formatBeverlyWilshire from "Profile/utils/formatBeverlyWilshire";
import {
  locationChanged,
  buildYourItineraryAction,
} from "store/analyticEvents";
import { selectIsUserLoggedIn } from "store/profile/profile.selectors";
import ToastNotification from "components/ToastNotification";
import { getStaticSiteProfileLink } from "utils/externalLinks";
import getItineraryItems from "./getItineraryItems";
import { BuildYourItinerary, Itinerary } from "./components";
import YourStayPerfected from "../UpcomingTripView/YourStayPerfected";
import ExperiencesIcon from "./components/ExperiencesIcon";
import SpaIcon from "./components/SpaIcon";
import DiningIcon from "./components/DiningIcon";

const { STATIC_SITE_URL } = env;

function fetchDiningOutlets({ language = "en", hotelCode }) {
  return axios
    .get(
      `${STATIC_SITE_URL}/alt/apps/fshr/feeds/booking/dining-outlets.json?version=2&language=${language}&owsCode=${hotelCode}&onlyOpenTable=true`
    )
    .then((res) => {
      return res.data?.featureFlags?.enableOpenTableCarousel
        ? res.data.diningOutlets
        : [];
    });
}

function getFormattedDateRange({ startDate, endDate }) {
  return [startDate, endDate]
    .map((d) => format(parse(d, "yyyy-MM-dd", new Date()), "MMM dd"))
    .join(" - ");
}

export default function ItineraryView({
  hotelCode,
  reservationId,
  highlightsMap,
  bookingMessages,
  taxes,
  goToArrivalTransportationView,
  goToArrivalAmenitiesView,
  isWebChatEnabled,
  openWebChat,
  isUnauthenticated = false,
}) {
  const { t, locale, i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const dispatchWithLocale = useDispatchWithLocale();
  const location = useLocation();

  const [ancillaries, setAncillaries] = useState([]);
  const { enableProfileOnWww } = useSelector(selectGlobalSettings);

  useEffect(() => {
    fetchDiningOutlets({ language: locale, hotelCode })
      .then((_ancillaries) => {
        const ctaLabel =
          _ancillaries.length === 1
            ? t("Explore & Reserve")
            : t("Reserve a table");
        return _ancillaries.map(
          ({ id, title, description, images, detailPageUrl }) => ({
            id,
            title,
            subTitle: ctaLabel,
            image: { src: images[0]?.asset?.url, alt: title },
            description,
            cta: {
              label: ctaLabel,
              href: `${detailPageUrl}?_s_icmp=itinerary_dining-reservation`,
            },
          })
        );
      })
      .then(setAncillaries);
  }, []);

  const dispatchAnalyticsEvent = (kind) => () => {
    dispatch(buildYourItineraryAction({ kind, hotelCode }));
  };

  // scroll to itinerary item based in URL hash
  useEffect(() => {
    const timeout = setTimeout(() => {
      const el = document.getElementById(location.hash.replace(/^#/, ""));
      if (el) {
        const { top } = el.getBoundingClientRect();
        window.scrollTo(0, top - 20);
      }
    });

    return () => {
      clearTimeout(timeout);
    };
  }, [location.hash]);

  const upcomingTrip = useSelector(
    selectBookingByReservationId({ reservationId })
  );

  const upcomingTrips = useSelector(selectUpcomingTripsOnly);
  const propertyCodes = upcomingTrips
    .map(({ hotelProducts: [hotelProduct] }) => hotelProduct.hotelCode)
    .filter(onlyUnique);
  const allPropertyContent = useSelector(selectAllPropertyContent);
  const propertyContent = allPropertyContent[hotelCode];
  const hasGuestRequests = useSelector(
    selectHasGuestRequests({ reservationId })
  );
  const guestRequests = useSelector(selectGuestRequests({ reservationId }));
  const propertyPhone = propertyContent?.propertyPhone || "";
  const propertyEmail =
    propertyContent?.hotel_email || propertyContent?.reservation_email || "";

  const fsRoomToCharge =
    get(upcomingTrip, ["hotelProducts", 0, "roomTypes", 0, "fsRoomToCharge"]) ||
    "";

  const roomTypeId = get(upcomingTrip, [
    "hotelProducts",
    0,
    "roomTypes",
    0,
    "roomTypeId",
  ]);

  const roomCode = fsRoomToCharge || roomTypeId;

  const accommodationContent = useSelector(
    selectAccommodationByRoomTypeId(hotelCode)(roomCode)
  );

  const guests = useSelector(
    selectRoomGuests({
      reservationId,
      productId: get(upcomingTrip, ["hotelProducts", 0, "productId"]),
    })
  );

  const itineraryItemsByDay =
    (upcomingTrip &&
      accommodationContent &&
      propertyContent &&
      getItineraryItems({
        upcomingTrip,
        guests,
        accommodationTitle: accommodationContent.title,
        propertyContent,
        guestRequests,
        highlightsMap,
        bookingMessages,
        taxes,
        restaurants: ancillaries,
      })) ||
    {};

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleUpcomingTripChange = (evt) => {
    history.push(evt.target.value);
  };

  const hasAllRequiredPropertyContent = propertyCodes.every((code) =>
    Boolean(allPropertyContent[code])
  );

  const missingPropertyContentPropertyCodes = propertyCodes.filter(
    (code) => !allPropertyContent[code]
  );

  // REQUIRED DATA TO RENDER
  const hasRequiredDataToRender = !(
    !hasGuestRequests ||
    !upcomingTrip ||
    !accommodationContent ||
    !hasAllRequiredPropertyContent
  );

  const isUserLoggedIn = useSelector(selectIsUserLoggedIn);

  const backTo = isUserLoggedIn
    ? profileRoutes.profileBookingPath.to({
        hotelCode,
        reservationId,
        locale,
      })
    : authenticationRoutes.unauthenticatedBookingPath.to({
        hotelCode,
        reservationId,
        locale,
      });

  useEffect(() => {
    if (missingPropertyContentPropertyCodes.length > 0) {
      dispatchWithLocale(
        fetchPropertyContent({
          propertyCodes: missingPropertyContentPropertyCodes,
        })
      );
    }

    return () => {
      if (missingPropertyContentPropertyCodes.length > 0) {
        dispatchWithLocale(fetchPropertyContentCancel());
      }
    };
  }, [missingPropertyContentPropertyCodes.join(",")]);

  useEffect(() => {
    if (hasRequiredDataToRender) {
      dispatch(
        locationChanged({
          location: window.location,
          hotelCode,
        })
      );
    }
  }, [hasRequiredDataToRender]);

  if (!hasRequiredDataToRender) {
    return <BookingFlowLoadingIndicator opaque />;
  }

  const qs = getQueryString({ _s_icmp: "itinerary_web_add_experience" });

  const getUrl = (url) =>
    [STATIC_SITE_URL, propertyContent.urlName, url].join("/") + qs;

  const itineraryQuickLinks = [
    {
      title: t("Experiences"),
      icon: <ExperiencesIcon />,
      href: getUrl("experiences"),
      onClick: dispatchAnalyticsEvent("experiences"),
    },
    {
      title: { html: t("Book&nbsp;a&nbsp;Spa") },
      icon: <SpaIcon />,
      href: getUrl("spa"),
      onClick: dispatchAnalyticsEvent("book a spa"),
    },
    {
      title: t("Dining"),
      icon: <DiningIcon />,
      href: getUrl("dining"),
      onClick: dispatchAnalyticsEvent("dining"),
    },
  ];

  return (
    <>
      <ToastNotification />
      {isWebChatEnabled && <WebChatButton onClick={openWebChat} />}
      <div
        className={`layout--profile-fullscreen view--itinerary ${i18n.dir(
          i18n.language
        )}`}
      >
        <div className="view--itinerary__header">
          <Link to={backTo} className="btn-back">
            <span>{t("Back")}</span>
          </Link>

          <h1 className="view--itinerary__header__title">
            {t("Your Itinerary")}
          </h1>

          {upcomingTrips.length > 1 && (
            <Dropdown
              name="selectedBookingReference"
              options={upcomingTrips.map(
                ({ startDate, endDate, hotelProducts: [hotelProduct] }) => {
                  return {
                    value: profileRoutes.profileBookingItineraryPath.to({
                      locale,
                      hotelCode: hotelProduct.hotelCode,
                      reservationId: hotelProduct.reservationId,
                    }),
                    label: `${
                      allPropertyContent[hotelProduct.hotelCode].shortName
                    } - (${getFormattedDateRange({ startDate, endDate })})`,
                  };
                }
              )}
              value={profileRoutes.profileBookingItineraryPath.to({
                locale,
                hotelCode,
                reservationId,
              })}
              onChange={handleUpcomingTripChange}
            />
          )}
        </div>

        <div className="view--itinerary__main">
          <h2
            className="view--itinerary__property"
            dangerouslySetInnerHTML={{
              __html: t(formatBeverlyWilshire(propertyContent.shortName)),
            }}
          />

          <p className="view--itinerary__dates">
            {getFormattedDateRange({
              startDate: upcomingTrip.startDate,
              endDate: upcomingTrip.endDate,
            })}
          </p>

          <BuildYourItinerary
            goToArrivalTransportationView={goToArrivalTransportationView}
            goToArrivalAmenitiesView={goToArrivalAmenitiesView}
            itineraryQuickLinks={itineraryQuickLinks}
            dispatchAnalyticsEvent={dispatchAnalyticsEvent}
            isUnauthenticated={isUnauthenticated}
          />

          <Itinerary
            hotelCode={hotelCode}
            itineraryItemsByDay={itineraryItemsByDay}
            isWebChatEnabled={isWebChatEnabled}
            openWebChat={openWebChat}
            itineraryQuickLinks={itineraryQuickLinks}
          />

          {(propertyPhone || propertyEmail) && (
            <div className="get-in-touch">
              <div className="get-in-touch__inner">
                <h3 className="get-in-touch__title">{t("Get In Touch")}</h3>
                <dl>
                  {propertyPhone && (
                    <>
                      <dt>{t("Phone")}</dt>
                      <dd>
                        <a href={`tel:${propertyPhone}`}>{propertyPhone}</a>
                      </dd>
                    </>
                  )}
                  {propertyEmail && (
                    <>
                      <dt>{t("Email")}</dt>
                      <dd>
                        <a href={`mailto:${propertyEmail}`}>{propertyEmail}</a>
                      </dd>
                    </>
                  )}
                </dl>
              </div>
            </div>
          )}

          {isUserLoggedIn && !isUnauthenticated && (
            <YourStayPerfected
              title={t("Perfect your stay")}
              content={t(
                "Set your stay preferences, share your interests, and manage email subscriptions"
              )}
              ctaHref={enableProfileOnWww && getStaticSiteProfileLink(locale)}
              ctaPath={
                !enableProfileOnWww && profileRoutes.profilePath.to({ locale })
              }
              ctaLabel={t("View Profile")}
              image="/images/profiles_2018/perfect-your-stay.jpg"
            />
          )}
        </div>
      </div>
    </>
  );
}
