import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { useDispatchWithLocale, useTranslation } from "hooks";
import Button from "Profile/components/Button";
import { logout } from "store/profile/profile.slice";
import { selectUpcomingTripsOnly } from "store/bookingHistory";
import { MediaContext } from "contexts/MediaContext";
import useNavigation from "Profile/EditProfileView/hooks/useNavigation";
import classNames from "classnames";
import { profileRoutes } from "Profile";
import { selectGlobalSettings } from "store/globalSettings";
import { getStaticSiteProfileLink, getStaticSiteProfileMyStaysLink } from "utils/externalLinks";

function ProfileLink({ label, to, onClick, className }) {
  return (
    <li className={classNames("fs-header__profile-flyout--link", className)}>
      <a
        className="ty-c2 fs-header__profile-flyout--linkButton"
        onClick={onClick}
        href={to}
      >
        {label}
      </a>
    </li>
  );
}

export default function ProfileLinks({ closeMenu, isProfileView }) {
  const { t, locale } = useTranslation();
  const media = useContext(MediaContext);
  const dispatchWithLocale = useDispatchWithLocale();
  const upcomingTrips = useSelector(selectUpcomingTripsOnly);
  const { enableProfileOnWww } = useSelector(selectGlobalSettings);

  const navigations = [
    ...(upcomingTrips.length > 0
      ? [
          {
            hash: "upcoming-trips",
            label: "Upcoming Trips",
          },
        ]
      : []),
    {
      hash: "your-preferences",
      label: "Your Preferences",
    },
    {
      hash: "your-interests",
      label: "Your Interests",
    },
  ];
  const { scrollToSection } = useNavigation({
    navigations: navigations.filter(({ hash }) => !!hash),
    topAdjust: 0,
  });

  const scrollToAndClose = (hash) => (evt) => {
    if (isProfileView) {
      evt.preventDefault();
      if (hash) {
        scrollToSection(hash);
      } else {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    }
    closeMenu();
  };

  return (
    <div className="fs-header__profile-flyout__columns fs-header__profile-flyout__columns--sign-out">
      <div className="fs-header__profile-flyout__column">
        {media.isLessThan.sm ? (
          <ul className="fs-header__profile-flyout--link__container">
            <ProfileLink
              label={t("Profile")}
              to={
                isProfileView
                  ? undefined
                  : (enableProfileOnWww ? getStaticSiteProfileLink(locale) : profileRoutes.profilePath.to({ locale }))
              }
              onClick={enableProfileOnWww ? undefined : scrollToAndClose()}
            />
            {upcomingTrips?.length > 0 && (
              <ProfileLink
                label={t("Upcoming Trips")}
                to={
                  isProfileView
                    ? undefined
                    : (enableProfileOnWww ? getStaticSiteProfileMyStaysLink(locale) : `${profileRoutes.profilePath.to({
                        locale,
                      })}#upcoming-trips`)
                }
                onClick={enableProfileOnWww ? undefined : scrollToAndClose("upcoming-trips")}
              />
            )}
            <ProfileLink
              label={t("Your Preferences")}
              to={
                isProfileView
                  ? undefined
                  : (enableProfileOnWww ? `${getStaticSiteProfileLink(locale)}/account/preferences` : `${profileRoutes.profilePath.to({
                      locale,
                    })}#your-preferences`)
              }
              onClick={enableProfileOnWww ? undefined : scrollToAndClose("your-preferences")}
            />
            <ProfileLink
              label={t("Your Interests")}
              to={
                isProfileView
                  ? undefined
                  : (enableProfileOnWww ? `${getStaticSiteProfileLink(locale)}/account/interests` : `${profileRoutes.profilePath.to({ locale })}#your-interests`)
              }
              onClick={enableProfileOnWww ? undefined : scrollToAndClose("your-interests")}
            />
            <ProfileLink
              label={t("Sign Out")}
              onClick={() => {
                dispatchWithLocale(logout());
                closeMenu();
              }}
              className="fs-header__profile-flyout--linkSignOut"
            />
          </ul>
        ) : (
          <>
            <h2>{t("Sign Out")}</h2>

            <Button
              onClick={() => {
                dispatchWithLocale(logout());
                closeMenu();
              }}
            >
              {t("Continue")}
            </Button>
          </>
        )}
      </div>
    </div>
  );
}
