import React from "react";
import { Redirect, Switch, Route, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";

import { getIsoCodeForLocale } from "config/languages";
import { useTranslation } from "hooks";
import { PlanYourStayView } from "BookingFlow/PlanYourStay";
import ErrorBoundary from "BookingFlow/components/ErrorBoundary";
import { getHMTLTagClassNames } from "utils";
import { selectIsUserLoggedIn, selectIsUserPersistent } from "store/profile";
import * as authenticationRoutes from "Authentication/authenticationRoutes";
import { selectGlobalSettings } from "store/globalSettings";
import {
  getStaticSiteProfileLink,
  getStaticSiteProfileSignInLink,
} from "utils/externalLinks";
import env from "config/env";
import { ProfileView } from "./ProfileView";
import { EditProfileView } from "./EditProfileView";
import { UpcomingTripViews } from "./UpcomingTripView";
import { StayPreferencesView } from "./StayPreferencesView";
import { YourInterestsView } from "./YourInterestsView";
import { EmailSubscriptionsView } from "./EmailSubscriptionsView";
import { MembershipView } from "./MembershipView";
import * as profileRoutes from "./profileRoutes";

const { RESERVATIONS_SITE_URL } = env;

function UpcomingTripRoute({
  match: {
    params: { hotelCode, reservationId },
  },
}) {
  return (
    <UpcomingTripViews hotelCode={hotelCode} reservationId={reservationId} />
  );
}

function MembershipRoute({
  match: {
    params: { hotelCode, membershipId, tierId },
  },
}) {
  return (
    <MembershipView
      hotelCode={hotelCode}
      membershipId={membershipId}
      tierId={tierId}
    />
  );
}

export default function Profile() {
  const { i18n, locale } = useTranslation();
  const location = useLocation();

  const isUserLoggedIn = useSelector(selectIsUserLoggedIn);
  const isUserPersistent = useSelector(selectIsUserPersistent);
  const { enableProfileOnWww } = useSelector(selectGlobalSettings);

  if (enableProfileOnWww) {
    if (isUserLoggedIn && !location.pathname.includes("/upcoming-trip")) {
      window.location.href = getStaticSiteProfileLink(locale);
      return null;
    }
    if (!isUserLoggedIn) {
      const redirectTo = encodeURIComponent(
        RESERVATIONS_SITE_URL + location.pathname + location.hash
      );
      window.location.href = getStaticSiteProfileSignInLink(
        locale,
        location.pathname.includes("/upcoming-trip") && redirectTo
      );
      return null;
    }
  }

  if (!isUserLoggedIn) {
    return (
      <Redirect
        to={{
          pathname: authenticationRoutes.signIn.to({
            locale,
          }),
          state: {
            afterSignInRedirectTo: location.pathname + location.hash,
          },
        }}
      />
    );
  }

  return (
    <ErrorBoundary
      fallbackUI={(hasReactError) => (
        <PlanYourStayView hasReactError={hasReactError} />
      )}
    >
      <Helmet>
        <html
          lang={getIsoCodeForLocale(i18n.language)}
          className={getHMTLTagClassNames({
            locale,
            dir: i18n.dir(i18n.language),
            kind: "fs-profile-2018",
          })}
        />
      </Helmet>

      <Switch>
        <Route
          path={profileRoutes.profileBookingPath.path}
          component={UpcomingTripRoute}
        />

        <Route
          path={profileRoutes.profileEmailSubscriptionsPath.path}
          component={EmailSubscriptionsView}
        />

        {!isUserPersistent && (
          <Route
            path={profileRoutes.editProfilePath.path}
            component={EditProfileView}
          />
        )}

        <Route
          path={profileRoutes.profilePath.path}
          exact
          component={ProfileView}
        />

        <Route
          path={profileRoutes.profileStayPreferencesPath.path}
          exact
          component={StayPreferencesView}
        />

        <Route
          path={profileRoutes.profileYourPreferencesPath.path}
          exact
          component={StayPreferencesView}
        />

        <Route
          path={profileRoutes.profileYourInterestsPath.path}
          exact
          component={YourInterestsView}
        />

        <Route
          path={profileRoutes.profileMembershipPath.path}
          component={MembershipRoute}
        />

        <Route
          render={() => (
            <Redirect
              to={profileRoutes.profilePath.to({
                locale,
              })}
            />
          )}
        />
      </Switch>
    </ErrorBoundary>
  );
}
