import React, { useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";

import { ThemeContext } from "contexts/ThemeContext";
import { getButtonClassName } from "Profile/components/Button/Button";

function Link({
  kind = "link",
  rounded = false,
  children,
  className,
  href,
  to,
  theme: manualTheme,
  ...props
}) {
  const { theme } = useContext(ThemeContext);
  if (href) {
    return (
      <a
        className={getButtonClassName({
          theme: manualTheme || theme,
          kind,
          rounded,
          className,
        })}
        href={href}
        {...props}
      >
        {children}
      </a>
    );
  }

  return (
    <RouterLink
      className={getButtonClassName({
        theme: manualTheme || theme,
        kind,
        rounded,
        className,
      })}
      to={to}
      {...props}
    >
      {children}
    </RouterLink>
  );
}

Link.propTypes = {
  kind: PropTypes.oneOf(["link", "primary", "secondary", "unstyled"]),
  rounded: PropTypes.bool,
};

Link.defaultProps = {
  kind: "link",
  rounded: false,
};

export default Link;
